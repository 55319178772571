import styled from "styled-components";
import {Link} from 'react-router-dom';


export const FooterContainer = styled.footer`
    background:linear-gradient(108deg,rgba(5,5,31,1) 60%,rgba(0, 12, 138,1) 100%);
`

export const FooterWrap = styled.div`
    padding: 48px 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1100px;
    margin: 0 auto;
`

export const FooterLinksContainer = styled.div`
position: relative;
font-size: 12px;
font-weight: bold;
margin-bottom: 10px;
color:#05051f;
text-align: center;
color: #fff;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
`
export const FooterLinksWrapper = styled.div`

`

export const FooterLinkItems = styled.div`
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    width: 300px;
    color: #fff;

    
`

export const FooterLinkTitle = styled.h1`
    font-size: 14px;
    margin-bottom: 16px;
`

export const FooterLink = styled(Link)`
    color: #fff;
    text-decoration: none;
    margin-bottom: 0.5rem;
    font-size: 14px;

    &:hover{
        color: #f57b1d;
        transition: 0.4s ease-in-out;
    }
`

export const SocialMedia = styled.section`
    max-width: 1000px;
    width: 100%;
`

export const SocialMediaWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1100px;
    margin: 40px auto 0 auto;

    @media screen and (max-width: 820px){
       flex-direction: column;
    }
`
export const SocialLogo = styled(Link)`
        color: #fff;
        justify-self: start;
        cursor: pointer;
        text-decoration: none;
        font-size: 0.8rem;
        display:flex;
        align-items: center;
        margin-bottom: 16px;
        font-weight: bold;

        &:hover{
            color: #f57b1d;
            transition: 0.4s ease-in-out;
        }
`

export const WebsiteRights = styled.small `
        color: #fff;
        margin-bottom: 16px;
`

export const SocialIcons = styled.div `
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 240px;
`

export const SocialIconLink = styled.a `
        color: #fff;
        font-size: 24px;

        &:hover{
            color: #f57b1d;
            transition: 0.4s ease-in-out;
        }
`

export const AMFIIcon = styled.img`
    height: 50px;
    width: 50px;
    margin-bottom: 10px;
`
export const FooterH2 = styled.div`
    position: relative;
    font-size: 12px;
    margin-bottom: 10px;
    margin-top: 10px;
    color:#05051f;
    text-align: center;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`
export const FooterH22 = styled.div`
    position: relative;
    font-size: 12px;
    margin-bottom: 10px;
    margin-top: 3px;
    color:#05051f;
    text-align: center;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`
export const FooterH222 = styled.div`
    position: relative;
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 30px;
    color:#05051f;
    text-align: center;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`
