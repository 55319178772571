import './App.css';
import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import Home from './pages';
import MutualFund from './pages/mutualfund';
import Insurance from './pages/insurance';
import Disclamer from './pages/disclamer';
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/mutualfund" element={<MutualFund/>}  />
        <Route path="/insurance" element={<Insurance/>}  />
        <Route path="/disclamer" element={<Disclamer/>}  />

      </Routes>
    </Router>
  );
}

export default App;
