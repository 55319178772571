//orange #f57b1d
//dark blue #05051f
//light blue #34399e



export const homeObjOne = {
    id: 'about',
    lightBg: false,
    lightText: true,
    lightTextDesc: false,
    darkText: false,
    display:true,
    headLine: 'About Us',
    description: 'MMS Financial Hub (India) Pvt. Ltd. (formerly known as Money Management Solutions (India) Pvt. Ltd.) was incorporated in the year 2006.',
    description1: 'The founder has rich 3 decades experience across the entire spectrum of financial service industry along with Investment Banking, Merchant Banking, Stock Broking and Insurance, which will play vital role in helping clients to fulfil their financial needs.',
    buttonLabel: 'Get Started',
    imgStart: false,
    bgcolor:true,
    img: require('../../images/bluemms1.svg').default,
    dark: false,
    primary: false
    
}

export const homeObjTwo = {
    id: 'mission',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    darkText: true,
    lightTop: true,
    display:true,

    headLine: 'Our Mission',
    description: 'Our Mission is to become essential part of our clients by providing differentiated products and services to help them in achieving their aspirations by giving them reliable, efficient, responsible financial products, which in turn helps in creating wealth out of their valuable savings.',
    description1: 'Anticipating client needs and motives according to their desire, risk appetite, goals, time horizon, purchasing power with expected  safety, liquidity and returns, we at MMS Fin Hub being customer-centric, understand and customise the financial products and serve the best to the clients accordingly.',
    buttonLabel: 'Get Started',
    imgStart: true,
    img: require('../../images/MMS.svg').default,
    dark: false,
    primary: false
   
}

export const homeObjFour = {
    id: 'contact',
    lightBg: true,
    lightText: false,
    darkText: true,
    lightTextDesc: false,
    lightTop: true,
    headLine: 'Reach Out',
    display:false,
    description: "Do you have any question about how MMS Fin Hub can help you? If Yes, Please do reach out to us!",
    description2: ' +91 9845037605, +91 9900187605',
    description3: 'customercare@mmsdirect.net',
    description4: 'No.40, Hemanth Nagar, Marathahalli Outer Ring Road, Marathahalli, Bangalore - 560037',
    img: require('../../images/LOGOWHITE.svg').default,
    imgStart: false,
    dark: false,
    primary: false
    
}