import React, {useState} from 'react';
import ReactPlayer from 'react-player/youtube'

import { HeroContainer, 
  HeroBg, 
  VideoBg,
  HeroBtnWrapper,
  HeroContent,
  HeroH1,
  HeroP,
  ArrowForward,
  ArrowRight 
} from './video';

const VideoSection = () => {
  const [hover, setHover] = useState(false)
  const [playing, setPlaying] = useState(false);

  const handleEnterViewport = function () {
    setPlaying(true);
  };
  const handleExitViewport = function () {
    setPlaying(false);
  };
  const onHover = () => {
    setHover(!hover)
  }

  return (
    <HeroContainer id='home'>
     <ReactPlayer
         style={{
          margin:'20px',

        }}
          url='https://www.youtube.com/watch?v=LgEYzo4Le1E'
     
          playing={playing}
          playsInline={true}

          fallback={<p>Loading</p>}
          config={{
            youtube: {
              playerVars: { origin: 'https://www.youtube.com' },
            },
          }}
          on
        />
        
    </HeroContainer>
  )
}

export default VideoSection
