import React from 'react'

import {
    InfoContainer,
    InfoWrapper,
    InfoRow,
    Column1,
    Column2,
    TextWrapper,
    TopLine,
    Heading,
    Heading1,

    Subtitle,
    Subtitle1,
    SocialIconLink,

    BtnWrap,
    Img,
    ImgWrap
} from './InfoElements';
const DisclamerSection = ({
    lightBg,
    id,
    imgStart,
    topLine,
    lightText,
    headLine,
    darkText,
    description,
    description1,
    description2,
    description3,
    description4,
    buttonLabel,
display,
    img,
    alt,
    bgcolor,
    primary,
    dark,
    dark2,
    lightTop
}) => {
  return (
   <>
   <InfoContainer lightBg = {lightBg} id={id}>
    <InfoWrapper>
            <Column1>
                <TextWrapper>
                    <Heading lightText={lightText}>Disclamer</Heading>
                    <Subtitle >By using the website www.mmsfinhub.com and signing up for our services, ("MMS FINANCIAL HUB (INDIA) PVT. LTD. ),
                         or providing us your personal information for any other purpose, you agree to this Privacy Policy and 
                         have read our disclaimers properly.</Subtitle>


                         <Subtitle>MMS FINANCIAL HUB (INDIA) PVT. LTD.  
                         key objective is to provide personalized and customized investment services to their 
                         clients under the given frame work and compliance laid down by SEBI and AMFI. </Subtitle>

<Subtitle> We are a registered distributor for mutual fund and have qualified members in the organization 
                         to create and counsel on financial calculation of individuals.This is to confirm that all the data 
                         provided in the website are extracted from some third party source or have been bought for the 
                         purpose of representations. </Subtitle>
                         <Subtitle>Any error or mistakes there in should be reported to us immediately. 
                         We are not responsible for any loss due to the mistakes,
                          error provided the MMS FINANCIAL HUB (INDIA) PVT. LTD. on the website.</Subtitle>
<Heading1>Investment in Securities markets are subject to market risks, read all the related documents carefully before investing.</Heading1>
<Heading1>Link to other sites</Heading1>
<Subtitle>For your convenience, this page may contain certain hyperlinks to other sites. While we try to link only to sites that share our high standards and respect for privacy, we are not responsible for the content or the privacy practices employed by other sites. We recommend that you check the policy of each site you visit and contact the owner or operator of such website if you have any concerns or questions.
</Subtitle>
                     
                    
                </TextWrapper>
            </Column1>
           
    </InfoWrapper>
   </InfoContainer>
   </>
  )
}


export default DisclamerSection
