import React, { useState } from 'react';
import Icon1 from '../images/LOGO BLUE BG.jpg';
import Sidebar from '../components/Sidebar';
import Navbar from '../Navbar';
import HeroSection from '../components/HeroSection'
import InfoSection from '../components/InfoSection'
import Services from '../components/Services';
import HomePage from '../components/Home';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import { homeObjFour, homeObjOne, homeObjTwo, 
  //homeObjFour 
} from '../components/InfoSection/Data';
import Footer from '../components/Footer';

const Home = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }


  return (
    <>
      
      <Sidebar isOpen={isOpen} toggle = {toggle}/>
      <Navbar toggle = {toggle}/>
      <FloatingWhatsApp phoneNumber={'9845037605'} avatar={Icon1} statusMessage={'Online'} chatMessage={'Hey!! How may I help you?'} accountName={'MMS Fin Hub'}/>
      <HomePage />
     
      <InfoSection {...homeObjOne}/>
      <InfoSection {...homeObjTwo}/>
      <Services/>
      <InfoSection {...homeObjFour}/>
      <Footer/>
    </>
  )
}

export default Home
