import { Form } from 'react-router-dom'
import styled from 'styled-components'
import {MdKeyboardArrowRight, MdArrowForward} from 'react-icons/md';


//orange #f57b1d
//dark blue #05051f
//light blue #34399e

export const HeroContainer = styled.div`
  //background-image:linear-gradient(to bottom left, #eadcfc,#e6f8fc,white,white);;

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    height: 600px;
    position: relative;
    z-index: 1;


`

export const HeroBg = styled.div`
    position: absolute;
    margin-top: 130px;

    margin-left: 80px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 52%;
    height: 62%;
    overflow: hidden;
    border-radius: 10px;
    border: 15px solid rgba(0,0,0,0.1);

`

export const VideoBg = styled.video`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    background: #fff;
 
`

export const HeroContent = styled.div`
    z-index: 3;
    max-width: 100%;
    position: absolute;
    padding: 8px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const HeroP = styled.p`
    id: box;

    margin-top: 24px;
    color: #fff;
    font-size: 24px;
    text-align: center;
    max-width: 1000px;

@media screen and (max-width: 768px){
    font-size: 15px;
}

@media screen and (max-width: 480px){
    font-size: 10px;
}
`

export const HeroBtnWrapper = styled.div`
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const ArrowForward = styled(MdArrowForward)`
    margin-left: 8px;
    font-size: 20px;
`

export const ArrowRight = styled(MdKeyboardArrowRight)`
    margin-left: 8px;
    font-size: 20px;
`
  
export const HeroH1 = styled.h1` 

font-size: 30px;

position: relative;

  left: 80%;
color: black;
font-family: 'Cormorant Garamond',Georgia,"Times New Roman",serif;



    @media screen and (max-width: 768px){
 display: none;
  }
  
  @media screen and (max-width: 480px){
    display:none;
  }
  `