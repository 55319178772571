import React from 'react'
import { Button } from '../ButtonElements'
import Bounce from 'react-reveal/Zoom';
import Slide from 'react-reveal/Slide';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    InfoContainer,
    InfoWrapper,
    InfoRow,
    Column1,
    Column2,
    TextWrapper,
    TopLine,
    Heading,
    Subtitle,
    Subtitle1,
    SocialIconLink,

    BtnWrap,
    Img,
    ImgWrap
} from './InfoElements';
import {FaEnvelope,FaPhoneAlt,FaMapMarkerAlt} from 'react-icons/fa'

const InfoSection = ({
    lightBg,
    id,
    imgStart,
    topLine,
    lightText,
    headLine,
    darkText,
    description,
    description1,
    description2,
    description3,
    description4,
    buttonLabel,
display,
    img,
    alt,
    bgcolor,
    primary,
    dark,
    dark2,
    lightTop
}) => {
  return (
   <>
   <InfoContainer lightBg = {lightBg} id={id}>
    <InfoWrapper>
        <InfoRow imgStart = {imgStart}>
            <Column1>
                <TextWrapper>
                    <TopLine lightTop={lightTop}>{topLine}</TopLine>
                    <Heading lightText={lightText}>{headLine}</Heading>
                    <Subtitle darkText={darkText}>{description}</Subtitle>
                    <Subtitle darkText={darkText}>{description1}</Subtitle>

                         
                        <SocialIconLink target="_blank" href="tel:9845037605"
                        aria-label="Phone" display={display}><FaPhoneAlt/> <Subtitle1 darkText={darkText}> {description2}</Subtitle1>
                        </SocialIconLink>
                               
                        <SocialIconLink  target="_blank" href="mailto:customercare@mmsdirect.net"
                        aria-label="Mail" display={display}><FaEnvelope/>  <Subtitle darkText={darkText}> {description3}</Subtitle>
                        </SocialIconLink>
                     
                        <SocialIconLink  target="_blank" 
                        aria-label="Address" display={display}><FaMapMarkerAlt/>  
                        <Subtitle darkText={darkText}>{description4}</Subtitle></SocialIconLink>  
                    
                </TextWrapper>
            </Column1>
            <Column2>
                <ImgWrap>
                   <Slide top> <Img src={img} alt={alt}/></Slide>
                </ImgWrap>
            </Column2>
        </InfoRow>
    </InfoWrapper>
   </InfoContainer>
   </>
  )
}

export default InfoSection
