import React from 'react'
import { 
    SidebarContainer, 
    Icon, 
    CloseIcon,
    SideBtnWrap,
    SidebarLink,
    SidebarRoute,
    SidebarWrapper,
    SidebarMenu
 }from './SidebarElements'

const Sidebar = ({toggle,isOpen}) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon>
        <CloseIcon/>
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
           <SidebarLink to="/" onClick={toggle}>Home</SidebarLink>
            <SidebarLink to="/" onClick={toggle}>We Are</SidebarLink>
           <SidebarLink to="/" onClick={toggle}>Our Mission</SidebarLink>
            <SidebarLink to="/" onClick={toggle}>Products</SidebarLink>
            <SidebarLink to="/" onClick={toggle}>Reach out</SidebarLink>

        </SidebarMenu>
        <SideBtnWrap>
            <SidebarRoute to="https://mms.wealthmagic.in/">Sign In</SidebarRoute>
        </SideBtnWrap>
      </SidebarWrapper>
    </SidebarContainer>
  )
}

export default Sidebar
