import { Form } from 'react-router-dom'
import styled from 'styled-components'
import {MdKeyboardArrowRight, MdArrowForward} from 'react-icons/md';


//orange #f57b1d
//dark blue #05051f
//light blue #34399e

export const HeroContainer = styled.div`
    background: #0c0c0c;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    height: 680px;
    position: relative;
    z-index: 1;
    //margin-top: 60px;

`

export const HeroBg = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
`

export const VideoBg = styled.video`
    width: 100%;
    height: 100%;

    -o-object-fit: cover;
    object-fit: cover;
    background: #232a34;

    @media screen and (max-width: 480px){
      width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    background: #232a34;
    }
`

export const HeroContent = styled.div`
    z-index: 3;
    max-width: 100%;
    position: absolute;
    padding: 8px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const HeroP = styled.p`
    id: box;

    margin-top: 24px;
    color: #fff;
    font-size: 24px;
    text-align: center;
    max-width: 1000px;

@media screen and (max-width: 768px){
    font-size: 15px;
}

@media screen and (max-width: 480px){
    font-size: 10px;
}
`

export const HeroBtnWrapper = styled.div`
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const ArrowForward = styled(MdArrowForward)`
    margin-left: 8px;
    font-size: 20px;
`

export const ArrowRight = styled(MdKeyboardArrowRight)`
    margin-left: 8px;
    font-size: 20px;
`
  
export const HeroH1 = styled.h1` 

font-size: 48px;
text-align: center;
position: relative;
color: #fff;
font-weight: bold!important;

letter-spacing: 3px!important;
overflow: hidden;
border-right: 2px solid hsl(0, 0%, 80%);
white-space: nowrap;
width:100%;

animation: typewriter 4s steps(44) 0.3s infinite normal both , blinkTextCursor 500ms infinite;

  @keyframes typewriter {
      from {
        width: 0;
      }
      to {
        width: 100%;
      }
    }
    @keyframes blinkTextCursor {
      from {
        border-right-color: hsl(0, 0%, 80%);
      }
      to {
        border-right-color: transparent;
      }
    }

    @media screen and (max-width: 768px){
      font-size: 24px;
  }
  
  @media screen and (max-width: 500px){
      font-size: 14px;
  }
  `