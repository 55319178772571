import { Form } from 'react-router-dom'
import styled from 'styled-components'
import {MdKeyboardArrowRight, MdArrowForward} from 'react-icons/md';

export const HomeContainer = styled.div`
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    height: auto;
    position: relative;
    z-index: 1;
    margin-top: -20px;
    @media screen and (max-width: 900px){
        height: 400px;
        margin-top: 40px;

    }

    @media screen and (max-width: 480px){
        height: 300px;
        margin-top: 40px;

    }
`
export const VideoBg = styled.video`
    width: 100%;
    height: 100%;

    -o-object-fit: cover;
    object-fit: cover;
    background: #fff;

    @media screen and (max-width: 480px){
      width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    background: #fff;
    }
`
