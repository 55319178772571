import React from 'react'
import MF from '../../images/mutualfunds.jpg';
import EQ from '../../images/equity.png';
import FIS from '../../images/fis.jpg';
import IN from '../../images/insurance.jpg';
import CO from '../../images/commodity.png';
import SP from "../../images/SP.jpg";
import {
    ServicesContainer,
    ServicesH1,
    ServicesH2,
    ServicesWrapper,
    ServicesCard,
    ServicesIcon,
    ServicesP
} from './ServicesElements';
import Zoom from 'react-reveal/Zoom';

const Services = () => {
  return (
    <ServicesContainer id="services">
        <ServicesH1>Our Services</ServicesH1>
        <ServicesWrapper>
            <Zoom top duration={700}>
            <ServicesCard to='/'>
                <ServicesIcon src={EQ}/>
                <ServicesH2>Equity</ServicesH2>
            </ServicesCard>
            <ServicesCard to='/mutualfund'>
                <ServicesIcon src={MF}/>
                <ServicesH2>Mutual Fund</ServicesH2>
            </ServicesCard>
            <ServicesCard to='/insurance'>
                <ServicesIcon src={IN}/>
                <ServicesH2>Insurance</ServicesH2>
            </ServicesCard>
            <ServicesCard to='/'>
                <ServicesIcon src={FIS}/>
                <ServicesH2>Fixed Income Securities</ServicesH2>
            </ServicesCard>
            <ServicesCard to='/'>
                <ServicesIcon src={CO}/>
                <ServicesH2>Commodity</ServicesH2>
            </ServicesCard>
            <ServicesCard to='/'>
                <ServicesIcon src={SP}/>
                <ServicesH2>Structured Products</ServicesH2>
            </ServicesCard>
            </Zoom>
        </ServicesWrapper>
      
    </ServicesContainer>
  )
}

export default Services
