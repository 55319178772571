import styled from 'styled-components'

export const HeroContainer = styled.div`
    background: #f5f5f5;

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    height: 400px;
    position: relative;
    z-index: 1;

    
`

export const HeroBg = styled.div`
    background: url('/images/photo.jpg')
  ;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 600px;
    overflow: hidden;
`



export const HeroContent = styled.div`
    z-index: 3;
    max-width: 100%;
    position: absolute;
    padding: 8px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const HeroP = styled.p`
    id: box;
top: 50px;
    font-family: 'Cormorant Garamond',Georgia,"Times New Roman",serif;
    background: -webkit-linear-gradient(#000000,#333);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
    color: #333;
    font-size: 50px;
    text-align: center;
    max-width: 1000px;

@media screen and (max-width: 768px){
    font-size: 15px;
}

@media screen and (max-width: 480px){
    font-size: 10px;
}
`


export const HeroH1 = styled.div` 

font-size: 130px;

position: relative;
background: -webkit-linear-gradient(#000000,#eee);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
font-family: 'Ruthie';



    @media screen and (max-width: 768px){
      font-size: 100px;

  }
  
  @media screen and (max-width: 500px){
      font-size: 50px;

  }
  `
  export const LogoIcon = styled.img`
  height: 40px;
  width: 40px;
  top: 230px;

  @media screen and (max-width: 768px){
    height: 40px;
    width: 40px;
  }
  
  @media screen and (max-width: 500px){
    height: 20px;
  width: 20px;
  }

`
