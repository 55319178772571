import React, {useState} from 'react';
import ReactPlayer from 'react-player/youtube'

import { HeroContainer, 
  HeroBg, 
  VideoBg,
  HeroBtnWrapper,
  HeroContent,
  HeroH1,
  HeroP,
  ArrowForward,
  ArrowRight,
  ServicesCard,
  ServicesWrapper 
} from './video';

const VideoSection = () => {
  const [hover, setHover] = useState(false)
  const [playing, setPlaying] = useState(false);

  const handleEnterViewport = function () {
    setPlaying(true);
  };
  const handleExitViewport = function () {
    setPlaying(false);
  };
  const onHover = () => {
    setHover(!hover)
  }

  return (
    <HeroContainer id='home'>
    <ServicesWrapper>

     <ReactPlayer
     
         style={{
          margin:'20px',

        }}
          url='https://www.youtube.com/watch?v=5E_LZoAGmwM'
     
          playing={playing}
          playsInline={true}
          width='auto'
          height='auto'
          fallback={<p>Loading</p>}
          config={{
            youtube: {
              playerVars: { origin: 'https://www.youtube.com' },
            },
          }}
          on
        />
 

         <ReactPlayer
         style={{
          margin:'20px',

        }}
         url='https://www.youtube.com/watch?v=OwSdwgsvnVQ'
  
         playing={playing}
         playsInline={true}
         width='auto'
         height='auto'
         fallback={<p>Loading</p>}
         config={{
           youtube: {
             playerVars: { origin: 'https://www.youtube.com' },
           },
         }}
         on
       />
        <ReactPlayer
         style={{
          margin:'20px',

        }}
         url='https://www.youtube.com/watch?v=IeRSr6F2NOM'
     
         playing={playing}
         playsInline={true}
         width='auto'
         height='auto'
         fallback={<p>Loading</p>}
         config={{
           youtube: {
             playerVars: { origin: 'https://www.youtube.com' },
           },
         }}
         on
       />
        </ServicesWrapper>

    </HeroContainer>
  )
}

export default VideoSection
