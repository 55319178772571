import React, { useState } from 'react';
import Sidebar from '../Mutualfundcomponents/Sidebar';
import Navbar from '../Mutualfundcomponents/Navbar';
import HeroSection from '../Mutualfundcomponents/HeroSection'
import InfoSection from '../components/InfoSection'
import VideoSection from '../Mutualfundcomponents/FrontVideo';
import VideoSection1 from '../Mutualfundcomponents/FrontVideo1';
import ScrollToTop from '../components/ScrollToTop'
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import Icon1 from '../images/LOGO BLUE BG.jpg';


import { homeObjOne, homeObjTwo, 
  //homeObjFour 
} from '../components/InfoSection/Data';
import Footer from '../components/Footer';

const MutualFund = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }


  return (
    <>
      <Sidebar isOpen={isOpen} toggle = {toggle}/>
      <ScrollToTop />
      <Navbar toggle = {toggle}/>
      <FloatingWhatsApp phoneNumber={'9845037605'} avatar={Icon1} statusMessage={'Online'} chatMessage={'Hey!! How may I help you?'} accountName={'MMS Fin Hub'}/>

      <HeroSection/>
      <VideoSection/>
      <VideoSection1/>
      <Footer/>
    </>
  )
}

export default MutualFund
