import React, {useState} from 'react';

import { HeroContainer, 
  HeroBg, 
  HeroContent,
  HeroH1,
  HeroP,

} from './HeroElements';

const HeroSection = () => {

  const [hover, setHover] = useState(false)

  const onHover = () => {
    setHover(!hover)
  }


  setTimeout(() => {
  
  }, 2000); 

  return (
    <HeroContainer id='home'>
      <HeroBg>
     
      </HeroBg>
      <HeroContent>
        <HeroH1>MMS FIN HUB</HeroH1>
        <HeroP>Learn more about Insurance</HeroP>
      </HeroContent>
    </HeroContainer>
  )
}

export default HeroSection
