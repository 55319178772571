import styled from 'styled-components'

export const InfoContainer = styled.div`
    color: #fff;
    background: #f9f9f9;

    @media screen and (max-width: 768px){
        padding: 100px 0;
}
`


export const InfoWrapper = styled.div`
    display: grid;
    z-index: 1;
    height: 1000px;
    width: 100%;
    max-width: auto;
    margin-right: auto;
    margin-left: auto;
    padding: 0 24px;
    justify-content: center;
`



export const Column1 = styled.div`
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col1;
    align: center;
`



export const TextWrapper = styled.div`
    max-width: 640px;
    padding-top: 0;
    padding-bottom: 60px;
`

export const TopLine = styled.p`
    color: ${({lightTop}) => (lightTop ? '#34399e' : '#f57b1d')};
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 16px;
`
export const Heading = styled.h1`
    margin-bottom: 24px;
    font-size: 48px;
    line-height: 1.1;
    font-weight: 600;
    color: ${({lightText}) => (lightText ? '#f7f8f8' : '#05051f')};
    font-family: 'Cormorant Garamond',Georgia,"Times New Roman",serif;

    @media screen and (max-width: 480px){
        font-size: 32px;
    }
`
export const Heading1 = styled.h4`
    margin-bottom: 24px;
    font-size: 18px;
    line-height: 1.1;

    color: ${({lightText}) => (lightText ? '#f7f8f8' : '#05051f')};
    font-family: 'Cormorant Garamond',Georgia,"Times New Roman",serif;

    @media screen and (max-width: 480px){
        font-size: 32px;
    }
`
export const Subtitle = styled.p`
    margin-bottom: 24px;
    line-height: 1.1;
    
    color: ${({lightText}) => (lightText ? '#f7f8f8' : '#05051f')};
    font-family: 'Cormorant Garamond',Georgia,"Times New Roman",serif;
    text-align: justify;

    @media screen and (max-width: 480px){
        font-size: 32px;
    }
`

