import First from "../../images/first.gif";
import Second from "../../images/second.gif";
import Third from "../../images/third.gif";

export default [
  {
    urls: First
  },
  {
    urls: Second,
  },
  {

    urls: Third,
  },
  
];