import React from 'react';
import af from '../../images/amfi.png'
import { animateScroll as scroll } from 'react-scroll';
import {
    FooterContainer,
    FooterWrap,
    FooterLinksContainer,
    FooterLinksWrapper,
    FooterLinkItems,
    FooterLinkTitle,
    FooterLink,
    SocialLogo,
    SocialMedia,
    SocialMediaWrap,
    WebsiteRights,
    AMFIIcon,
    FooterH2,
    FooterH22,
    FooterH222,
    SocialIcons,
    SocialIconLink
} from './FooterElements';

import {FaFacebook, FaInstagram, FaLinkedin, FaTwitter, FaYoutube} from 'react-icons/fa'

const Footer = () => {

    const toggleHome = () => {
        scroll.scrollToTop()
      }
  return (
    <FooterContainer>
        <FooterWrap>
            
            <SocialMedia>
                <SocialMediaWrap>
               
                <FooterLinksWrapper>
                    <FooterLinkItems>
                        <SocialLogo to='/' onClick={toggleHome}>MMS FINANCIAL HUB (INDIA) PVT. LTD.</SocialLogo>
                            <FooterLink to='/disclamer'>Dislamer</FooterLink>
                    
                    
                    </FooterLinkItems>
                </FooterLinksWrapper>
      
                    <WebsiteRights>
                        MMS FIN HUB © {new Date().getFullYear()} All Rights Reserved.
                    </WebsiteRights>
                    <FooterH2>
                    <AMFIIcon src={af}/>
                    AMFI Registered 
                    <FooterH22>
                    Mutual Funds Distributor
                    </FooterH22>
                    <FooterH222>
                        ARN-28448
                    </FooterH222>
                    </FooterH2>
                </SocialMediaWrap>
            </SocialMedia>
        </FooterWrap>
    </FooterContainer>
  )
}

export default Footer
