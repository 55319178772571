import styled from 'styled-components'

export const InfoContainer = styled.div`
    color: #fff;
    background: ${({lightBg}) => (lightBg ? '#f9f9f9' : '#05051f')};

    @media screen and (max-width: 768px){
        padding: 100px 0;
}
`
export const SocialIconLink = styled.a `
        color: #05051f;
        font-size: 24px;
        text-decoration: none;
        display: ${({display}) => (display ? 'none' : 'flex')};
        &:hover{
            color: #de9623;
            transition: 0.4s ease-in-out;
        }
`

export const InfoWrapper = styled.div`
    display: grid;
    z-index: 1;
    height: 800px;
    width: 100%;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 24px;
    justify-content: center;
`

export const InfoRow = styled.div`
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;
    grid-template-areas: ${({imgStart}) => (imgStart ? `'col2 col1'` : `'col1 col2'`)};

    @media screen and (max-width: 768px){
        grid-template-areas: ${({imgStart}) => (imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`)};
}
`

export const Column1 = styled.div`
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col1;
    align: center;
`

export const Column2 = styled.div`
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col2;

`
export const Subtitle1 = styled.p`
    max-width: 440px;
    margin-bottom: 35px;
    font-size: 18px;
    line-height: 24px;
    color: ${({darkText}) => (darkText ? '#05051f' : '#fff')};
`
export const TextWrapper = styled.div`
    max-width: 640px;
    padding-top: 0;
    padding-bottom: 60px;
`

export const TopLine = styled.p`
    color: ${({lightTop}) => (lightTop ? '#34399e' : '#f57b1d')};
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 16px;
`
export const Heading = styled.h1`
    margin-bottom: 24px;
    font-size: 48px;
    line-height: 1.1;
    font-weight: 600;
    color: ${({lightText}) => (lightText ? '#f7f8f8' : '#05051f')};
    font-family: 'Cormorant Garamond',Georgia,"Times New Roman",serif;

    @media screen and (max-width: 480px){
        font-size: 32px;
    }
`
export const Subtitle = styled.p`
    max-width: 440px;
    margin-bottom: 35px;
    font-size: 18px;
    line-height: 24px;
    color: ${({darkText}) => (darkText ? '#05051f' : '#fff')};
    text-align: justify;
    font-family: 'Cormorant Garamond',Georgia,"Times New Roman",serif;
`

export const BtnWrap = styled.div`
    display: flex;
    justify-content: flex-start;
`

export const ImgWrap = styled.div`
    max-width: 555px;
    height: 100%;
    background-color: '#05051f';
    align: center;
`

export const Img = styled.img`
        width: 100%;
        margin: 0 0 10px 0;
        padding-right: 0;
        background-color: '#05051f';
        align: center;
`