import React, {useState} from 'react';
import ReactPlayer from 'react-player/youtube'

import { HeroContainer, 
  HeroBg, 
  VideoBg,
  HeroBtnWrapper,
  HeroContent,
  HeroH1,
  HeroP,
  ArrowForward,
  ArrowRight,
  ServicesWrapper
} from './video';

const VideoSection1 = () => {
  const [hover, setHover] = useState(false)
  const [playing, setPlaying] = useState(false);

  const handleEnterViewport = function () {
    setPlaying(true);
  };
  const handleExitViewport = function () {
    setPlaying(false);
  };
  const onHover = () => {
    setHover(!hover)
  }

  return (
    <HeroContainer id='home'>
          <ServicesWrapper>

     <ReactPlayer
         style={{
          margin:'20px',

        }}
         url='https://www.youtube.com/watch?v=XEmWQgBRIwM'
     
         playing={playing}
         playsInline={true}
         width='auto'
         height='auto'
         fallback={<p>Loading</p>}
         config={{
           youtube: {
             playerVars: { origin: 'https://www.youtube.com' },
           },
         }}
         on
       />
 <ReactPlayer
         style={{
          margin:'20px',

        }}
         url='https://www.youtube.com/watch?v=BZPp-lXcN4o'
     
         playing={playing}
         playsInline={true}
         width='auto'
         height='auto'
         fallback={<p>Loading</p>}
         config={{
           youtube: {
             playerVars: { origin: 'https://www.youtube.com' },
           },
         }}
         on
       />
 <ReactPlayer
         style={{
          margin:'20px',

        }}
         url='https://www.youtube.com/watch?v=W8kooE7sE84&t=3s'
     
         playing={playing}
         playsInline={true}
         width='auto'
         height='auto'
         fallback={<p>Loading</p>}
         config={{
           youtube: {
             playerVars: { origin: 'https://www.youtube.com' },
           },
         }}
         on
       />
    </ServicesWrapper>

    </HeroContainer>
  )
}

export default VideoSection1
